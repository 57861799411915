<template>
  <a-layout>
    <Nav />
    <slot />

    <Footer />
  </a-layout>
</template>

<script type='ts' setup>
// this without wrapper
import Footer from '~/components/layouts/footer.vue'
import Nav from '~/components/layouts/nav.vue'
import { useAclStore } from '../store/acl';

const $acl = useAclStore()
const fbID = 'tODO'

onMounted(() => {
  $acl.checkSession();
})


const $nav = useNavStore()
useHead({
  title: computed(() => $nav.title ? `${$nav.title} - Cel-Elements`: 'Cel-Elements'),
})

</script>

<style scoped>

</style>
